import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/Layout"
import {Helmet} from "react-helmet";
import {SEO} from "../components/Seo";
import Newsletter from "../components/Newsletter";

const Article = ({data}) => {
    const { html } = data.markdownRemark
    const { title } = data.markdownRemark.frontmatter
    return(
      <Layout key={data.link}>
          <Helmet>
              <title>{title} | SAP Career Guide - A beginner’s manual on SAP careers for students and professionals</title>
          </Helmet>
          <section className="bg-blue-200 text-gray-800 pt-16 pb-10 text-left">
              <div className="mx-auto max-w-3xl px-4 sm:px-6 xl:max-w-5xl xl:px-0">
                  <h1 className="font-semibold md:text-4xl text-2xl pb-4">{title}</h1>
              </div>
          </section>
          <div className="mx-auto max-w-3xl px-4 sm:px-6 xl:max-w-5xl xl:px-0 flex flex-row flex-wrap py-10">
              <div className="w-full">
                  <div className="w-full mt-9 article">
                      <div className="prose lg:prose-xl" dangerouslySetInnerHTML={{ __html: html }}></div>
                      <Newsletter></Newsletter>
                  </div>
              </div>
          </div>
        </Layout>
    )
}

export default Article

export const Head = () => (
    <SEO/>
)

export const query = graphql`
  query ArticleQuery($slug: String) {
    markdownRemark(frontmatter: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
            excerpt
      }
    }
  }
`
